export type RemoteConfigType = {
  data: RemoteConfigDataType;
};

export type TeamsConfig = {
  team: string;
  id: string;
  imageUrl: string;
};

export type LandingPage = {
  id: string;
  url: string;
};

export type PlayerConfiguration = {
  liveEventEndGracePeriodSeconds: number;
  playerRestartErrorCodes: string[];
  playerRestartAttempts: number;
};

export type ConvivaConfiguration = {
  isEnabled: boolean;
  key: string;
  touchstoneURL: string;
  ecoIntegrationIsEnabled: string;
};

export type RemoteConfigDataType = {
  urls: RemoteConfigDataTypeUrls;
  vars: RemoteConfigDataTypeVars;
  teams: TeamsConfig[];
  promotionConfiguration: RemoteConfigDataTypePromotionConfiguration;
  upsell: Array<UpsellA>;
  heroConfig: {
    ctyIgnoringTransparency: {
      title: Array<string>;
      description: Array<string>;
    };
    promotionsDestination: {
      id: string;
      btnName: string;
      destination: string;
    }[];
  };
  availablePaymentOptions: {
    APPLE_PAY: boolean;
    CREDIT_CARD: boolean;
    PAYPAL: boolean;
  };
  landingPages: Array<LandingPage>;
  playerConfiguration: PlayerConfiguration;
  conviva: ConvivaConfiguration;
  migration: MigrationConfiguration;
  minimalWebVersion: boolean;
  webPathControl: {
    blockedPaths: Array<string>;
  };
};

export type RemoteConfigDataTypeUrls = {
  AUTH_BASE_URL: string;
  CATALOG_BASE_URL: string;
  CHECK_REDEEM_BASE_URL: string;
  CONTENT_AUTH_BASE_URL: string;
  DATASTORE_BASE_URL: string;
  DEVICE_REGISTER_BASE_URL: string;
  EVERGENT_BASE_URL: string;
  EVERGENT_IFRAME_BASE_URL: string;
  EVERGENT_TVE_PROVIDE_URL: string;
  GEO_LOCATION_BASE_URL: string;
  HEARTBEAT_BASE_URL: string;
  IMAGE_RESIZER_BASE_URL: string;
  STOREFRONT_BASE_URL: string;
  STREAMCONCURRENCY_BASE_URL: string;
  ADOBE_AUTH_URL: string;
  QUICKPLAY_ASSETS_CDN_URL: string;
  WEB_APP_URL: string;
  IS_VISIBLE_PROGRESSBAR_ON_LIVE: boolean;
  FREEWHEEL_API_URL: string;
  SPONSOR_LOGO_URL_L: string;
  SPONSOR_LOGO_URL_S: string;
};

export type RemoteConfigDataTypeVars = {
  ACTION_REQUIRED: boolean;
  CAST_CHANNEL_NAMESPACE: string;
  CAST_RECEIVER_APPLICATION_ID: string;
  CLIENT_NAME: string;
  FREE_CONTENT_IDENTIFIER: string;
  CONTAINERS_LIMIT: number;
  DEVICE_MODEL_NO: string;
  DEVICE_USER_AGENT: string;
  EVERGENT_API_KEY: string;
  EVERGENT_CHANNEL_PARTNER_ID: string;
  FORCE_TIZEN: string;
  HEARTBEAT_INTERVAL_SYNC: number;
  HEARTBEAT_MAX_ALLOWED_FAILURES: number;
  HERO_BANNER_INTERVAL: number;
  MAX_REELS_LENGTH: number;
  MPARTICLE_API_KEY_WEB: string;
  OUT_OF_MARKET_ZONE: string;
  PURCHASE_FORBIDDEN_ZONES: string[];
  WEB_APP_URL: string;
  PLAYER_LOGGER: boolean;
  ONETRUST_ENABLED: boolean;
  ODDS_ENABLED: boolean;
  COMPANION_ADS_QUERY_PARAMETER: string;
  COMPANION_ADS_ENABLED: boolean;
  PAUSE_SUBSCRIPTION_ENABLED: boolean;
  IS_COMPANION_ADS_CLICKABILITY_ENABLED: boolean;
  SEASON_TICKET_HOLDER_PROMOTION_REDEMPTION: boolean;
  SQUARE_SDK_APP_ID: string;
  SQUARE_SDK_LOCATION_ID: string;
  SHOULD_BLOCK_COUCH_RIGHTS_ERROR: boolean;
  SHOULD_BLOCK_LOCATION_ERROR: boolean;
  RESTRICT_TO_SUBSCRIPTION_ONLY: boolean;
};

export type PromotionStep = {
  caption: string;
  imgAlt: string;
  imgSrc: string;
};

interface ZoneToContent {
  zone: string;
  contentId: string;
}

export interface ZoneToPpgConfig {
  id: string;
  gameImgSrc?: string;
  zoneContentIdMap: ZoneToContent[];
}

export enum PromotionType {
  FANDUEL_COLLECTION = 'fanduel-collection',
  SEASON_TICKET_HOLDER = 'season-ticket-holder',
  ZONE_TO_PPG = 'zone-to-ppg',
}

export type Promotion = {
  collectionId?: string;
  ctaLabel: string;
  id: string;
  logo: {
    alt: string;
    src: string;
  };
  skipPlanSelection: boolean;
  skipTeamSelection?: boolean;
  steps: PromotionStep[];
  subtitle: string;
  title: string;
  trackEventUrl: string;
  type: PromotionType;
  zoneToPpgConfigurations?: ZoneToPpgConfig[];
};

export type RemoteConfigDataTypePromotionConfiguration = Promotion[];

export const UpsellType = {
  PPG_UPSELL_A: 'PPGUpsellA',
  PPG_UPSELL_B: 'PPGUpsellB',
};

export type UpsellA = {
  id: string;
  title: string;
  description: string;
  ctaLabel: string;
  ctaContinueLabel: string;
  ctaAction: {
    type: string;
    preselectedOption: string;
  };
};

export enum MigrationTrigger {
  blockPurchase = 'blockPurchase',
  appStart = 'appStart',
  blockRegistration = 'blockRegistration',
}

export type Migartion = {
  isEnabled?: boolean;
  url?: string;
  title?: string;
  description?: string;
  faqText?: string;
  faqUrl?: string;
  faqUrlText?: string;
  usePng?: boolean;
  imageID?: string;
  imageProportionDefault?: string;
  imageProportionMobile?: string;
  imageType?: number;
  closeBtnText?: string;
  goToURLText?: string;
  showAlways?: boolean;
  mobileMinTopMargin?: number;
  defaultMinTopMargin?: number;
};

export type MigrationConfiguration = {
  [key in MigrationTrigger]: Migartion;
};
