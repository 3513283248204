import { useCallback } from 'react';
import { NavigateFunction, To, matchPath, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { NavigateOptions } from 'react-router/dist/lib/context';

import { ROUTES, RouteName } from '../Constants/routes';
import { useEnv } from '../components/EnvProvider/EnvProvider';
import { routesConfig } from '../routes/routesConfig';

export const getPathPattern = (pathToGet: string) => {
  const paths = Object.values(ROUTES);
  const matchedRoute = paths.find((path) => matchPath(path, pathToGet));

  return matchedRoute || '';
};

export const useExtendedNavigate = (): NavigateFunction => {
  const navigate = useNavigate();
  const location = useLocation();

  const { minimalWebVersion } = useEnv();

  const extendedNavigate = useCallback(
    (path: To, options?: NavigateOptions) => {
      if (!navigator.onLine) {
        return navigate(ROUTES[RouteName.Networkerror], {
          replace: false,
          state: { desiredLocation: path },
        });
      }

      if (minimalWebVersion && typeof path === 'string') {
        const routeSettings = routesConfig[getPathPattern(path)];
 
        if (!routeSettings?.minimalWebVersion) {
          return navigate(ROUTES[RouteName.Settings]);
        }
      }
      
      return navigate(path, {
        ...options,
        state: {
          ...options?.state,
          previousLocation: location?.pathname + location.search,
        },
      });
    },
    [navigate, location.pathname, location.search, minimalWebVersion],
  );

  return <NavigateFunction>extendedNavigate;
};
