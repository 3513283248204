import { ROUTES, RouteName, RouteParam } from '../../../Constants/routes';
import { matchingPath } from '../../../helpers/path';
import { routesConfig } from '../../../routes/routesConfig';
import { ITranslationData } from '../../../store/translations';
import { getRouteByNameWithParams } from '../../../utils/routes';
import { NavigationItemType, NavigationItems } from '../Navigation.types';
import { isSettingsActive } from '../helpers/isSettingsActive';

export const getNavigationItems = (
  t: ITranslationData,
  featureFlags: { [key: string]: boolean },
  minimalWebVersion: boolean,
): NavigationItems =>
  [
    {
      isAvailableOnMobile: true,
      isElementActiveValidator: matchingPath,
      label: t.header_home,
      path: ROUTES[RouteName.Home],
      type: NavigationItemType.HOME,
    },
    {
      isAvailableOnMobile: false,
      label: t.header_live,
      options: [],
      type: NavigationItemType.LIVE,
    },
    {
      isAvailableOnMobile: true,
      isElementActiveValidator: matchingPath,
      label: t.header_teams,
      path: ROUTES[RouteName.Teams],
      type: NavigationItemType.TEAMS,
    },
    {
      isAvailableOnMobile: true,
      label: t.header_schedule,
      options: [
        {
          isAvailableOnMobile: true,
          isElementActiveValidator: matchingPath,
          label: t.header_games,
          path: getRouteByNameWithParams(RouteName.Schedule, { [RouteParam.TabName]: 'games' }),
          type: NavigationItemType.SCHEDULE,
        },
        {
          isAvailableOnMobile: true,
          isElementActiveValidator: matchingPath,
          label: t.header_channels,
          path: getRouteByNameWithParams(RouteName.Schedule, { [RouteParam.TabName]: 'channels' }),
          type: NavigationItemType.SCHEDULE,
        },
      ],
      type: NavigationItemType.SCHEDULE,
    },
    {
      isAvailableOnMobile: true,
      isElementActiveValidator: matchingPath,
      isHiddenByFeatureFlag: !featureFlags.ODDS_ENABLED,
      label: t.header_odds,
      path: ROUTES[RouteName.Odds],
      type: NavigationItemType.ODDS,
    },
    {
      isAvailableOnMobile: true,
      isElementActiveValidator: isSettingsActive,
      label: t.header_settings,
      path: ROUTES[RouteName.Settings],
      type: NavigationItemType.SETTINGS,
    },
  ]
    .filter((item) => !item.isHiddenByFeatureFlag)
    .filter((item) => {
      // filter out menu items that are not available in minmialWeb mode
      if (minimalWebVersion) {
        return routesConfig[item.path || '']?.minimalWebVersion;
      }
      return true;
    });
