import { RemoteConfigDataType } from './api/remoteConfigApi/remoteConfigApi.types';
import { defaultEnvs } from './components/EnvProvider/EnvProvider';
import { Envs } from './components/EnvProvider/EnvProvider.types';
import configManager from './services/config/ConfigManager';
import { getAvailablePaymentOptions } from './utils/getAvailablePaymentOptions';

export const envName = String(process.env.REACT_APP_ENV).trim();

export const defaultUrls = {
  ADOBE_AUTH_URL: '',
  AUTH_BASE_URL: '',
  CATALOG_BASE_URL: '',
  CHECK_REDEEM_BASE_URL: '',
  CONTENT_AUTH_BASE_URL: '',
  DATASTORE_BASE_URL: '',
  DEVICE_REGISTER_BASE_URL: '',
  EVERGENT_BASE_URL: '',
  EVERGENT_IFRAME_BASE_URL: '',
  EVERGENT_TVE_PROVIDE_URL: '',
  FREEWHEEL_API_URL: '',
  GEO_LOCATION_BASE_URL: '',
  HEARTBEAT_BASE_URL: '',
  IMAGE_RESIZER_BASE_URL: '',
  QUICKPLAY_ASSETS_CDN_URL: '',
  STOREFRONT_BASE_URL: '',
  STREAMCONCURRENCY_BASE_URL: '',
  WEB_APP_URL: '',
};

function setupEnv(remoteConfig: RemoteConfigDataType): Envs {
  const vars = remoteConfig?.vars;
  const urls = remoteConfig?.urls ?? defaultUrls;
  const promotions = remoteConfig?.promotionConfiguration ?? [];
  const upsell = remoteConfig?.upsell ?? [];
  const heroConfig = remoteConfig?.heroConfig ?? [];
  const conviva = remoteConfig?.conviva ?? {
    isEnabled: false,
    key: '',
    touchstoneURL: '',
  };
  const webPathControl = remoteConfig?.webPathControl ?? {
    blockedPaths: [],
  };

  const migration = remoteConfig.migration ?? defaultEnvs.migration;

  const landingPages = remoteConfig?.landingPages ?? [];
  const playerConfiguration = remoteConfig?.playerConfiguration ?? {
    liveEventEndGracePeriodSeconds: 0,
    playerRestartAttempts: 0,
    playerRestartErrorCodes: [],
  };

  const availablePaymentOptions = getAvailablePaymentOptions(remoteConfig?.availablePaymentOptions);

  const {
    EVERGENT_BASE_URL,
    EVERGENT_IFRAME_BASE_URL,
    EVERGENT_TVE_PROVIDE_URL,
    DATASTORE_BASE_URL,
    STOREFRONT_BASE_URL,
    AUTH_BASE_URL,
    DEVICE_REGISTER_BASE_URL,
    CONTENT_AUTH_BASE_URL,
    GEO_LOCATION_BASE_URL,
    CATALOG_BASE_URL,
    IMAGE_RESIZER_BASE_URL,
    CHECK_REDEEM_BASE_URL,
    WEB_APP_URL,
    HEARTBEAT_BASE_URL,
    STREAMCONCURRENCY_BASE_URL,
    ADOBE_AUTH_URL,
    QUICKPLAY_ASSETS_CDN_URL,
    FREEWHEEL_API_URL,
    SPONSOR_LOGO_URL_L,
    SPONSOR_LOGO_URL_S,
  } = urls;

  const envUrls = {
    ACTIVE_SUBSCRIPTIONS_URL: `${EVERGENT_BASE_URL}/msgn/getActiveSubscriptions`,
    ADD_OR_REMOVE_FAVORITE_URL: `${EVERGENT_BASE_URL}/msgn/addOrRemoveFavourites`,
    ADD_SUBSCRIPTIONS_URL: `${EVERGENT_BASE_URL}/msgn/addSubscriptions`,
    ADD_TVOD_ORDER: `${EVERGENT_BASE_URL}/msgn/addTVODOrder`,
    ADOBE_AUTHORIZE_URL: `${ADOBE_AUTH_URL}/api/v1/authorize`,
    ADOBE_GET_MEDIA_SHORT_TOKEN_URL: `${ADOBE_AUTH_URL}/api/v1/tokens/media`,
    ADOBE_TOKENS_AUTHZ_URL: `${ADOBE_AUTH_URL}/api/v1/tokens/authz`,
    ANONYMIZE_ACCOUNT_URL: `${EVERGENT_BASE_URL}/msgn/anonymizeAccount`,
    APPLE_STORE_APP_URL: 'https://apps.apple.com/us/app/msg-go/id948017586',
    AUTHORIZATION_ENDPOINT: `${AUTH_BASE_URL}/oauth2/token`,
    CHANGE_EMAIL_URL: `${EVERGENT_BASE_URL}/msgn/updateProfile`,
    CHANGE_PASSWORD_URL: `${EVERGENT_BASE_URL}/msgn/changePassword`,
    CLIENT_REGISTERATION_ENDPOINT: `${DEVICE_REGISTER_BASE_URL}/`,
    CONTENT_AUTH_ENDPOINT: `${CONTENT_AUTH_BASE_URL}`,
    DISCONNECT_TVE_ACCOUNT_URL: `${EVERGENT_BASE_URL}/msgn/disconnectTVEAccount`,
    EVERGENT_ADD_OUR_UPDATE_COUCH_RIGHTS: `${EVERGENT_BASE_URL}/msgn/addOrUpdateCouchRights`,
    EVERGENT_CHECKOUT_URL: `${EVERGENT_IFRAME_BASE_URL}/?accessToken={accessToken}&zipCode={zipCode}&serviceType={serviceType}&actionType={actionType}`,
    EVERGENT_COUCH_RIGHTS: `${EVERGENT_BASE_URL}/msgn/getCouchRights`,
    EVERGENT_TVE_PROVIDER_URL: `${EVERGENT_TVE_PROVIDE_URL}/checkout?accessToken={accessToken}&deviceid={deviceid}&device-info={device-info}&session_token={session_token}&shouldRedirect={shouldRedirect}&redirectionUrl={redirectionUrl}&tveLinkCode={tveLinkCode}`,
    FORGOT_CONTACT_PASSWORD_URL: `${EVERGENT_BASE_URL}/msgn/forgotContactPassword`,
    FREEWHEEL_API_URL,
    GENERATE_ACTIVATION_CODE: `${EVERGENT_BASE_URL}/msgn/generateDeviceActivationCode`,
    GEO_LOCATION_LOOKUP_URL: `${GEO_LOCATION_BASE_URL}/device/location/lookup`,
    GET_ADOBE_ACCESSE_TOKEN_URL: `${EVERGENT_BASE_URL}/msgn/getAdobeAccessToken`,
    GET_COLLECTION_TAB_CONTAINER: `${CATALOG_BASE_URL}/catalog/collection/{id}/containers`,
    GET_CONTACT_URL: `${EVERGENT_BASE_URL}/msgn/getContact`,
    GET_ENTITLEMENTS_URL: `${EVERGENT_BASE_URL}/msgn/getEntitlements`,
    GET_FAVORITES_TEAMS_URL: `${EVERGENT_BASE_URL}/msgn/getFavourites`,
    GET_LIVE_EVENT: `${DATASTORE_BASE_URL}/content/urn/resource/catalog/liveevent`,
    GET_LIVE_EVENTS: `${DATASTORE_BASE_URL}/content/liveevent/filter`,
    GET_LIVE_EVENTS_LIVE: `${DATASTORE_BASE_URL}/content/liveevent/live`,
    GET_PAYMENTS: `${EVERGENT_BASE_URL}/msgn/getPayments`,
    GET_PRODUCTS_URL: `${EVERGENT_BASE_URL}/msgn/getProducts`,
    GET_PROMOTION_URL: `${DATASTORE_BASE_URL}/content/urn/resource/catalog/promotion/{id}`,
    GET_PRORATE_URL: `${EVERGENT_BASE_URL}/msgn/getProrate`,
    GET_STOREFRONT_CHANNELS: `${DATASTORE_BASE_URL}/content/urn/resource/catalog/channel`,
    GET_STOREFRONT_CHANNEL_AIRING: `${DATASTORE_BASE_URL}/content/airing/live`,
    GET_STOREFRONT_CHANNEL_EPG: `${DATASTORE_BASE_URL}/content/epg`,
    GET_STOREFRONT_LIST: `${STOREFRONT_BASE_URL}/storefront/list`,
    GET_STOREFRONT_TABS: `${STOREFRONT_BASE_URL}/storefront/{id}/tabs`,
    GET_STOREFRONT_TAB_CONTAINER: `${CATALOG_BASE_URL}/catalog/storefront/{id}/{tabId}/containers`,
    GET_STRING_URL: `${EVERGENT_BASE_URL}/msgn/getString`,
    GET_SUBSCRIPTION_HISTORY_URL: `${EVERGENT_BASE_URL}/msgn/getSubscriptionHistory`,
    GET_VIDEO_BY_ID_URL: `${DATASTORE_BASE_URL}/content/urn/resource/catalog/{contentType}/{id}`,
    GET_VOUCHER_CODE_URL: `${EVERGENT_BASE_URL}/msgn/getVoucherDetails`,
    GET_ZIP_CODE_INFO_URL: `${DATASTORE_BASE_URL}/content/urn/resource/catalog/zipcode/{zipCode}`,
    GOOGLE_PLAY_STORE_APP_URL: 'https://play.google.com/store/apps/details?id=com.msgi.msggo',
    HEARTBEAT_URL: `${HEARTBEAT_BASE_URL}/`,
    LOGIN_URL: `${EVERGENT_BASE_URL}/msgn/getOAuthAccessTokenv2`,
    LOG_OUT_ALL_DEVICES_URL: `${EVERGENT_BASE_URL}/msgn/logoutAllDevices`,
    LOG_OUT_URL: `${EVERGENT_BASE_URL}/msgn/logOutUser`,
    MEDIA_CHECKREDEEM_URL: `${CHECK_REDEEM_BASE_URL}/media/content/checkredeem`,
    PAUSE_SUBSCRIPTION_URL: `${EVERGENT_BASE_URL}/msgn/pauseSubscription`,
    POST_EXPRESS_CHECKOUT_URL: `${EVERGENT_BASE_URL}/msgn/paypalSetExpressCheckout`,
    QUICKPLAY_ASSETS_CDN_URL,
    REFRESH_TOKEN_URL: `${EVERGENT_BASE_URL}/msgn/refreshToken`,
    REGISTER_DEVICE_URL: `${EVERGENT_BASE_URL}/msgn/registerDevice`,
    REMOVE_SUBSCRIPTION_URL: `${EVERGENT_BASE_URL}/msgn/removeSubscription`,
    RESET_PASSWORD_URL: `${EVERGENT_BASE_URL}/msgn/resetPassword`,
    RESUME_SUBSCRIPTION_URL: `${EVERGENT_BASE_URL}/msgn/resumeSubscription`,
    SEARCH_ACCOUNT_V2_URL: `${EVERGENT_BASE_URL}/msgn/searchAccountV2`,
    SET_STRING_URL: `${EVERGENT_BASE_URL}/msgn/setString`,
    SIGN_UP_URL: `${EVERGENT_BASE_URL}/msgn/createUser`,
    SPONSOR_LOGO_URL_L,
    SPONSOR_LOGO_URL_S,
    STREAMCONCURRENCY_URL: `${STREAMCONCURRENCY_BASE_URL}/`,
    TEAMS_LIST_URL_BY_ZONE: `${DATASTORE_BASE_URL}/content/urn/resource/catalog/team`,
    TEAMS_VIDEOS_URL: `${CATALOG_BASE_URL}/catalog/collection/{teamKey}/containers`,
    THUMB_IMAGE_URL: `${IMAGE_RESIZER_BASE_URL}/image/{assetId}/{assetSize}.{format}?width={width}`,
    TRANSLATIONS_URL:
      envName === 'prod'
        ? 'https://dtc-api.msgnetworks.com/v1/Configuration/Translation'
        : 'https://dtc-api-stage.msgnetworks.com/v1/Configuration/Translation',
    WEB_VIEW_CONFIG_URL:
      envName === 'prod'
        ? 'https://dtc-api.msgnetworks.com/v1/Configuration/WebViewConfig'
        : 'https://dtc-api-stage.msgnetworks.com/v1/Configuration/WebViewConfig',
  };

  const envVars = {
    ...vars,
    BLOCK_PURCHASING: true,
    CLIENT_ID: String(process.env.REACT_APP_CLIENT_ID).trim() ?? '',
    CLIENT_SECRET: String(process.env.REACT_APP_CLIENT_SECRET).trim() ?? '',
    DATA_ZOOM_KEY: String(process.env.REACT_APP_DATA_ZOOM_KEY).trim() ?? '',
    DATA_ZOOM_LOGGER: false,
    DEFAULT_CHANNEL: 'MSG',
    EVERGENT_API_KEY: String(process.env.REACT_APP_EVERGENT_API_KEY).trim() ?? '',
    FIREBASE_API_KEY: String(process.env.REACT_APP_FIREBASE_API_KEY).trim() ?? '',
    FIREBASE_APP_ID: String(process.env.REACT_APP_FIREBASE_APP_ID).trim() ?? '',
    FIREBASE_AUTH_DOMAIN: String(process.env.REACT_APP_FIREBASE_AUTH_DOMAIN).trim() ?? '',
    FIREBASE_ENABLED: true,
    FIREBASE_MEASUREMENT_ID: String(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID).trim() ?? '',
    FIREBASE_MESSAGING_SENDER_ID:
      String(process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID).trim() ?? '',
    FIREBASE_PROJECT_ID: String(process.env.REACT_APP_FIREBASE_PROJECT_ID).trim() ?? '',
    FIREBASE_STORAGE_BUCKET: String(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET).trim() ?? '',
    FORCE_TIZEN: localStorage.FORCE_TIZEN === 'true',
    FORCE_ZONE_KEY: localStorage.FORCE_ZONE_KEY || process.env.REACT_APP_FORCE_ZONE_KEY,
    HEARTBEAT_INTERVAL_SYNC: 60000,
    HEARTBEAT_MAX_ALLOWED_FAILURES: 2,
    HERO_BANNER_CAROUSEL_INTERVAL_IN_MS: 6000,
    IS_PLAYER_IN_STORYBOOK_MODE: false,
    IS_VISIBLE_PLAY_PAUSE_BUTTON_ON_LIVE: false,
    IS_VISIBLE_PROGRESSBAR_ON_LIVE: false,
    LANGUAGE: 'en',
    MPARTICLE_API_KEY_WEB: String(process.env.REACT_APP_MPARTICLE_API_KEY_WEB).trim(),
    NUMBER_OF_HOURS_LOCATION_IS_VALID: 1,
    PARTNER_LOGO_VISIBILITY: true,
    PAYPAL_CLIENT_ID: String(process.env.REACT_APP_PAYPAL_CLIENT_ID).trim() ?? '',
    PLAYER_FORCE_BACK_TO_LIVE_BUTTON: false,
    PLAYER_FORCE_RESTART_BUTTON: false,
    PLAYER_LOGGER: false,
    QA_USER_NAME: 'QA User',
    REDUX_LOG: String(process.env.REACT_APP_REDUX_LOG).trim() !== 'no',
    SHOW_UPDATE_PAYMENT_METHOD:
      localStorage.SHOW_UPDATE_PAYMENT_METHOD ||
      process.env.REACT_APP_SHOW_UPDATE_PAYMENT_METHOD !== 'no',
    STREAM_CONCURRENCY_MAX_RETRIES: 2,
    STREAM_CONCURRENCY_SYNC_INTERVAL_IN_MILLIS: 60000,
    TRANSLATIONS_API_KEY: String(process.env.REACT_APP_TRANSLATIONS_API_KEY).trim() ?? '',
    TVE_ADOBE_REQUESTOR: 'MSG',
    TVE_ADOBE_RESOURCE: 'MSGGO',
    VIP_USER_NAME: 'VIP User',
    WEB_APP_URL:
      String(process.env.REACT_APP_WEB_APP_URL_FORCE_FROM_ENV).trim() !== 'yes'
        ? location.origin || (WEB_APP_URL as string)
        : (WEB_APP_URL as string),
    WEB_VIEW_CONFIG_API_KEY: String(process.env.REACT_APP_WEB_VIEW_CONFIG_API_KEY).trim() ?? '',
    X_CLIENT_ID: String(process.env.REACT_APP_X_CLIENT_ID).trim() ?? '',
    X_FORWARDED_FOR: localStorage.X_FORWARDED_FOR || process.env.REACT_APP_X_FORWARDED_FOR,
  };

  const env = {
    ...envUrls,
    ...envVars,
    PROMOTIONS: promotions,
    UPSELL: upsell,
    availablePaymentOptions,
    conviva,
    heroConfig,
    landingPages,
    migration,
    minimalWebVersion: !!remoteConfig?.minimalWebVersion,
    playerConfiguration,
    webPathControl,
  };

  return env;
}

function getEnv() {
  return configManager?.remoteConfig ? setupEnv(configManager.remoteConfig) : defaultEnvs;
}

export { getEnv, setupEnv };
