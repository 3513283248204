import { ROUTES, RouteName } from '../Constants/routes';

type RoutesConfig = Record<
  string,
  {
    hasZipCodeValidation?: boolean;
    isPrivate?: boolean;
    redirectTo?: string;
    ignoreOptionalRoute?: boolean;
    /* if screen name is provided 'Screen view' event is triggered on page entry */
    screenName?: string;
    forceNavbar?: boolean;
    noNavbar?: boolean;
    noFooter?: boolean;
    noFooterVhGrowth?: boolean;
    noContentPadding?: boolean;
    customLayout?: boolean;
    minimalWebVersion?: boolean;
  }
>;

export const routesConfig: RoutesConfig = {
  [ROUTES[RouteName.Home]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'home',
  },
  [ROUTES[RouteName.HomePlayer]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'player-details',
  },
  [ROUTES[RouteName.SelectSubscription]]: {
    isPrivate: true,
    redirectTo: '/home/select-subscription',
    screenName: 'select-subscription',
  },
  [ROUTES[RouteName.HomeCollection]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'collection',
  },
  [ROUTES[RouteName.Promotion]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'promotion',
  },
  [ROUTES[RouteName.TeamsCollection]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'collection',
  },
  [ROUTES[RouteName.HomeSeeAll]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'see-all',
  },
  [ROUTES[RouteName.Schedule]]: {
    hasZipCodeValidation: true,
    ignoreOptionalRoute: true,
    isPrivate: true,
    screenName: 'schedule',
  },
  [ROUTES[RouteName.ScheduleGamesPlayer]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'player-details',
  },
  [ROUTES[RouteName.ScheduleChannelsPlayer]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'player-details-live',
  },
  [ROUTES[RouteName.SeeAllPlayer]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'see-all',
  },
  [ROUTES[RouteName.Teams]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
  },
  [ROUTES[RouteName.TeamsPlayer]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'player-details',
  },
  [ROUTES[RouteName.TeamsSeeAll]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'see-all',
  },
  [ROUTES[RouteName.Settings]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    minimalWebVersion: true,
  },
  [ROUTES[RouteName.Welcome]]: {
    customLayout: true,
    minimalWebVersion: true,
    screenName: 'welcome',
  },
  [ROUTES[RouteName.ForbiddenRegion]]: {
    minimalWebVersion: true,
    screenName: 'forbidden-region',
  },
  [ROUTES[RouteName.GeolocationError]]: {
    minimalWebVersion: true,
    screenName: 'geolocation-error',
  },
  [ROUTES[RouteName.FavoriteTeams]]: {
    noFooter: true,
    screenName: 'favourites-teams',
  },
  [ROUTES[RouteName.Signup]]: {
    minimalWebVersion: true,
    screenName: 'sign-up',
  },
  [ROUTES[RouteName.Login]]: {
    minimalWebVersion: true,
    screenName: 'sign-in',
  },
  [ROUTES[RouteName.ServerError]]: {
    minimalWebVersion: true,
    screenName: 'server-error',
  },
  [ROUTES[RouteName.SubscriberAgreement]]: {
    forceNavbar: true,
    screenName: 'subscriber-agreement',
  },
  [ROUTES[RouteName.Networkerror]]: {
    noFooter: true,
    screenName: 'network-lost-page',
  },
  [ROUTES[RouteName.EditPassword]]: {
    noFooter: true,
    screenName: 'edit-password',
  },
  [ROUTES[RouteName.TermsOfUse]]: {
    forceNavbar: true,
    minimalWebVersion: true,
    screenName: 'terms-of-use',
  },
  [ROUTES[RouteName.PastTermsOfUse]]: {
    forceNavbar: true,
    minimalWebVersion: true,
    screenName: 'past-terms-and-conditions',
  },
  [ROUTES[RouteName.PrivacyChoices]]: {
    forceNavbar: true,
    minimalWebVersion: true,
    screenName: 'privacy-choices',
  },
  [ROUTES[RouteName.PrivacyPolicy]]: {
    forceNavbar: true,
    minimalWebVersion: true,
  },
  [ROUTES[RouteName.Faqs]]: {
    forceNavbar: true,
    minimalWebVersion: true,
    screenName: 'faqs',
  },
  [ROUTES[RouteName.Payment]]: {
    customLayout: true,
    screenName: 'payment',
  },
  [ROUTES[RouteName.activatePayment]]: {
    customLayout: true,
    screenName: 'activate-payment',
  },
  [ROUTES[RouteName.ForgotPassword]]: {
    screenName: 'forgot-password',
  },
  [ROUTES[RouteName.ForgotPasswordDone]]: {
    screenName: 'forgot-password-done',
  },
  [ROUTES[RouteName.ResetPassword]]: {
    screenName: 'reset-password',
  },
  [ROUTES[RouteName.ResetPasswordDone]]: {
    screenName: 'reset-password-done',
  },
  [ROUTES[RouteName.ActivateSignIn]]: {
    minimalWebVersion: true,
    screenName: 'activate-sign-in',
  },
  [ROUTES[RouteName.ActivateSignUp]]: {
    minimalWebVersion: true,
    screenName: 'activate-sign-up',
  },
  [ROUTES[RouteName.Activate]]: {
    customLayout: true,
    minimalWebVersion: true,
    screenName: 'activate',
  },
  [ROUTES[RouteName.Provider]]: {
    customLayout: true,
    isPrivate: true,
    minimalWebVersion: true,
    screenName: 'activate-enter-code',
  },
  [ROUTES[RouteName.SettingsTab]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    minimalWebVersion: true,
  },
  [ROUTES[RouteName.ChannelsPlayer]]: {
    isPrivate: true,
    screenName: 'player-details-live',
  },
  [ROUTES[RouteName.GamesPlayer]]: {
    isPrivate: true,
    screenName: 'player-details',
  },
  [ROUTES[RouteName.Live]]: {
    hasZipCodeValidation: true,
    isPrivate: true,
    screenName: 'player-details-live',
  },
  [ROUTES[RouteName.AllSet]]: {
    customLayout: true,
    isPrivate: true,
    minimalWebVersion: true,
    screenName: 'all-set',
  },
  [ROUTES[RouteName.AllSetPayment]]: {
    customLayout: true,
    isPrivate: true,
    screenName: 'all-set-payment',
  },
  [ROUTES[RouteName.UpdateFavoriteTeams]]: {
    isPrivate: true,
    noFooter: true,
    noNavbar: true,
    screenName: 'update-favorite-teams',
  },
  [ROUTES[RouteName.SettingsProfileUpdateFavoriteTeams]]: {
    isPrivate: true,
    noFooter: true,
    noNavbar: true,
    screenName: 'update-favorite-teams',
  },
  [ROUTES[RouteName.TveConnectionStatus]]: {
    customLayout: true,
    isPrivate: true,
  },
  [ROUTES[RouteName.Odds]]: {
    customLayout: true,
    hasZipCodeValidation: false,
    isPrivate: true,
    noContentPadding: true,
    screenName: 'odds-standalone',
  },
};
